.allcard{
    background-color: #5CDB95;
    border: 1px solid #43b878;
    border-radius: 10px;
    margin-top: 0rem;
}


.allcard_color{
    background-color: white;
    padding: 2rem 0.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.allcard_color h2{
    font-size: 30px;
    color: #0a0e0a;
    margin-bottom: 0;
    margin-top: 0;
}

.allcard_color p{
    font-size: 13px;
    color: #0a0e0a;
    margin-top: 0;
}

.allcard_title{
    text-align: left;
    padding-top: 0rem;
    padding-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0;
}

.allcard_title h2{
    font-size: 20px;
    color: white;
    margin-bottom: 1.5rem;
}

.allcard_title p{
    margin-top: 0;
    margin-bottom: 0;
    color: #0a0e0a;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
}

.allcard_button{
    text-align: left;
    margin: 0 2rem;
}

.allcard_button a{
    text-decoration: none;
}

.allcard_button button{
    margin-top: 0;
    margin-bottom: 2rem;
    background-color: white;
    padding: 0.7rem 2rem;
    color: #0a0e0a;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px #5CDB95;
    cursor: pointer;
}

.allcard_button button:hover{
    background-color: #0a0e0a;
    color: #5CDB95;
}