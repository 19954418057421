.footer {
  background-color: #0a0e0a;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer_name{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer_name .text{
  margin-right: 1rem;
}

.footer_contacts {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.email,
.linkedin,
.twitter,
.github {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2rem;
}

.icon p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
}

.text p {
  display: flex;
  margin: 0;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.text p a {
    text-decoration: none;
    color: white;
}

.email p,
.linkedin p,
.twitter p,
.github p {
  margin-left: 0.5rem;
  color: white;
  font-size: 15px;
}

/*-------responsive---------*/

@media screen and (max-width: 810px) {
  .footer_name p {
    font-size: 15px;
  }

  .email p,
  .linkedin p,
  .twitter p,
  .github p {
    margin-left: 0.3rem;
    color: white;
    font-size: 13px;
  }
}

@media screen and (max-width: 630px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .email,
  .linkedin,
  .twitter,
  .github {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .footer_name{
    margin-bottom: 2rem;
  }

  .footer_contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .email,
  .linkedin,
  .twitter,
  .github {
    margin-bottom: 1rem;
  }
}
