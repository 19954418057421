.landingpage {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0a0e0a;
  padding-bottom: 9rem;
  height: 100vh;
}

.planets{
  display: flex;
  z-index: 20;
}

.maintext_name {
  color: #5CDB95;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 0;
  padding-bottom: 2rem;
  width: auto;
}

.uptext h5 {
  margin: 0;
  text-align: left;
  font-size: 17px;
  font-weight: 300;
}

.middletext h2 {
  margin: 0;
  text-align: left;
  font-size: 55px;
  font-weight: 500;
}

.lowtext h6 {
  margin: 0;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  position: relative;
}

.lowtext h6::before {
  content: "";
  display: block;
  width: 300px;
  height: 1px;
  margin-top: 0.7rem;
  background: #5CDB95;
  position: absolute;
}

.text_des {
  margin: 0;
  width: 100%;
  padding-top: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0a0e0a;
}

.des p {
  color: white;
  font-size: 70px;
  text-align: left;
  align-items: center;
  font-weight: 200;
  line-height: 75px;
}

.helloworld {
  font-weight: 800;
  font-size: 80px;
}

.italicspan {
  color: #5CDB95;
}

.capitalitalic {
  font-style: italic;
  font-weight: 600;
}

.italicspanwhite {
  color: white;
}

.opensource {
  color: #5CDB95;
  font-weight: 300;
}

.des_gif img {
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/* ---------------------responsive---------------------- */

@media screen and (max-width:1200px){
  .landingpage{
    padding-top: 10rem;
  }
}

@media screen and (max-width: 750px) {
  .landingpage{
    padding-top: 0rem;
  }

  .des p {
    font-size: 55px;
    line-height: 60px;
  }

  .helloworld {
    font-size: 70px;
  }

  .maintext_name{
    padding-bottom: 3rem;
  }
}


@media screen and (max-width: 630px) {
  .des p {
    font-size: 45px;
    line-height: 50px;
  }

  .helloworld {
    font-size: 60px;
  }

  .uptext h5 {
    font-size: 15px;
  }

  .middletext h2 {
    font-size: 45px;
  }

  .lowtext h6 {
    font-size: 12px;
  }

  .lowtext h6::before {
    width: 200px;
  }

  .text_des {
    padding-top: 3rem;
  }

  .maintext_name{
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .des p {
    font-size: 35px;
    line-height: 40px;
  }

  .helloworld {
    font-size: 50px;
  }

  .uptext h5 {
    font-size: 14px;
  }

  .middletext h2 {
    font-size: 35px;
  }

  .lowtext h6 {
    font-size: 12px;
  }

  .lowtext h6::before {
    width: 150px;
  }

  .des_gif img {
    width: 90%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .maintext_name {
    margin-top: 12rem;
    margin-bottom: 2rem;
  }

  .landingpage {
    padding-bottom: 0rem;
  }

  .text_des {
    padding-top: 0rem;
    margin-top: 0rem;
  }

  .section_padding {
    padding: 2rem 2rem;
  }
}


@media screen and (max-width:400px){
  .text_des {
    padding-top: 3;
    margin-top: -1rem;
  }
}

@media screen and (max-width: 370px) {

  .landingpage {
    padding-top: 4rem;
  }
  .des p {
    font-size: 30px;
    line-height: 35px;
  }

  .helloworld {
    font-size: 45px;
  }

  .uptext h5 {
    font-size: 12px;
  }

  .middletext h2 {
    font-size: 30px;
  }

  .lowtext h6 {
    font-size: 10px;
  }

  .lowtext h6::before {
    width: 120px;
  }

  .des_gif img {
    width: 90%;
  }

  .maintext_name {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  .text_des {
    padding-top: 0;
    margin-top: -2rem;
  }
}
