.navbar {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0a0e0a;
  /* box-shadow: 0px 2px 7px rgba(8, 8, 8, 0.503); */
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 0;
  padding-left: 4rem;
}

.initials p {
  margin: 0 auto;
  justify-content: center;
  font-size: 27px;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-weight: 550;
}

.logo,
.navbar_menu {
  color: #5CDB95;
}

.navbar_menu {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 0;
  padding-right: 4rem;
}

.logo_title h2 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}

.logo_title span {
  font-weight: 300;
}

.logo_name p {
  margin-top: 0;
  margin-bottom: 0;
}

.navbar_menu {
  display: flex;
  flex-direction: row;
}

.navbar_menu_items {
  font-size: 20px;
  font-weight: 300;
  color: white;
  margin-left: 2rem;
}

.navbar_menu_items a {
  text-decoration: none;
  padding-bottom: 5px;
  font-weight: 200;
  color: white;
}

.navbar_menu_items a::after {
  content: "";
  width: 0;
  height: 1.5px;
  background: #5CDB95;
  display: block;
  margin: 5px auto;
  transition: 0.5s;
}

.navbar_menu_items a:hover {
  color: #5CDB95;
}

.navbar_menu_items a:hover::after {
  width: 70%;
}

.navbar_menu_items a.active {
  font-weight: 400;
  color: #5CDB95;
}

@media screen and (max-width: 550px) {
  .logo {
    padding-left: 2rem;
  }
  .navbar_menu {
    padding-right: 2rem;
  }
  .logo_title h2 {
    font-size: 18px;
  }
  .logo_name p {
    font-size: 12px;
  }
  .navbar_menu_items p {
    font-size: 15px;
  }
}

@media screen and (max-width: 428px) {
  .logo {
    padding-left: 2rem;
  }

  .navbar_menu {
    padding-right: 2rem;
  }

  .logo_title h2 {
    font-size: 15px;
  }
  .logo_name p {
    font-size: 12px;
  }
  .navbar_menu_items p {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .navbar {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo {
    padding-left: 0;
    padding-bottom: 0.5rem;
  }

  .navbar_menu {
    padding-right: 0;
  }

  .navbar_menu_items {
    margin: auto 1rem;
  }

  .navbar_menu_items p {
    margin-bottom: 0;
  }
  .logo_title h2 {
    font-size: 18px;
  }
  .logo_name p {
    font-size: 14px;
  }
  .navbar_menu_items p {
    font-size: 15px;
  }
}
