.pla1,
.pla2,
.pla3,
.pla4,
.pla5 {
  position: relative;
}

.each_planet {
  position: absolute;
}

.pla1 {
  width: 7rem;
  z-index: 2;
  top: 5rem;
  left: 52rem;
}

.pla2 {
  width: 6rem;
  top: 22rem;
  left: 74rem;
}

.pla3 {
  width: 12rem;
  top: 10rem;
  left: 5rem;
}

.pla4 {
  width: 12rem;
  top: 25rem;
  left: 12rem;
}

.pla5 {
  width: 9rem;
  top: 39rem;
  left: 58rem;
}


/*----------responsive-------------------------------------------------- */

@media screen and (max-width:1200px){
    .pla1 {
        width: 5rem;
        top: 5rem;
        left: 45rem;
      }
      
      .pla2 {
        width: 8rem;
        top: 15rem;
        left: 55rem;
      }
      
      .pla3 {
        width: 10rem;
        top: 1rem;
        left: 3rem;
      }
      
      .pla4 {
        width: 12rem;
        top: 40rem;
        left: 50rem;
      }
      
      .pla5 {
        width: 8rem;
        top: 30rem;
        left: 3rem;
      }
    
    }


@media screen and (max-width:750px){
    .pla1 {
        width: 5rem;
        top: 10rem;
        left: 25rem;
      }
      
      .pla2 {
        width: 4rem;
        top: 30rem;
        left: 27rem;
      }
      
      .pla3 {
        width: 9rem;
        top: 5rem;
        left: 3rem;
      }
      
      .pla4 {
        width: 12rem;
        top: 40rem;
        left: 17rem;
      }
      
      .pla5 {
        width: 6rem;
        top: 30rem;
        left: 3rem;
      }
    
    }


@media screen and (max-width:550px){
    .pla1 {
        width: 5rem;
        top: 5rem;
        left: 17rem;
      }
      
      .pla2 {
        width: 4rem;
        top: -7rem;
        left: 22rem;
      }
      
      .pla3 {
        width: 9rem;
        top: -4rem;
        left: 3rem;
      }
      
      .pla4 {
        width: 8rem;
        top: 28rem;
        left: 17rem;
      }
      
      .pla5 {
        width: 6rem;
        top: 20rem;
        left: 3rem;
      }
    
    }


@media screen and (max-width:400px){
    
.pla1 {
    width: 3rem;
    left: 20px;
    
  }
  
  .pla2 {
    width: 7rem;
    left: 14rem;
    top: 1px;
  }
  
  .pla3 {
    width: 7rem;
    top: -7rem;
    left: 10px;
  }
  
  .pla4 {
    width: 9rem;
    top: 25rem;
    left: 10rem;
  }
  
  .pla5 {
    width: 6rem;
    top: 22rem;
    left: 10px;
    
  }
  
}



@media screen and (max-width:370px){
    
    .pla1 {
        width: 3rem;
        z-index: 2;
        left: 15px;
        top: -2rem;
      }
      
      .pla2 {
        width: 5rem;
        left: 12rem;
        top: -5rem;
      }
      
      .pla3 {
        width: 7rem;
        top: -10rem;
        left: 10px;
      }
      
      .pla4 {
        width: 9rem;
        top: 19.5rem;
        left: 8rem;
      }
      
      .pla5 {
        width: 6rem;
        top: 15rem;
        left: 7px;
        
      }
      
    }