.projects {
  background-color: #5cdb95;
  padding-top: 4rem;
}

.projects_title h1 {
  text-align: left;
  color: white;
  font-size: 150px;
  width: 60%;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 2rem;
}

.project_des p {
  margin-top: 0;
  text-align: left;
  color: #0a0e0a;
  font-size: 25px;
}

.recent_cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.all_projects {
  padding-top: 5rem;
}

.allprojects_title h1 {
  text-align: left;
  color: white;
  font-size: 100px;
  line-height: 1;
  width: 50%;
  font-weight: 800;
  padding-bottom: 2rem;
}

.all_cards {
  margin-top: 5rem;
  margin-bottom: 7rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
  position: relative;
  gap: 5rem;
  justify-content: center;
}

/*-------------responsive- all-projects------------*/

@media screen and (max-width: 480px) {
  .all_projects {
    padding-top: 3rem;
  }

  .allprojects_title h1 {
    font-size: 80px;
    padding-bottom: 1rem;
  }
}


@media screen and (max-width: 380px) {
    .all_projects {
      padding-top: 3rem;
    }
  
    .allprojects_title h1 {
      font-size: 65px;
    }
  }

  
@media screen and (max-width: 330px) {
    .all_projects {
      padding-top: 3rem;
    }
  
    .allprojects_title h1 {
      font-size:50px;
      padding-bottom: 0rem;
    }
  }


  /*-----responsive- recent projects------------*/

  @media screen and (max-width: 950px){
    .projects_title h1 {
        font-size: 120px;
      }
      
      .project_des p {
        font-size: 35px;
      }
    
    .recent_cards {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  }


  
@media screen and (max-width: 580px) {
    .projects_title h1 {
        font-size: 90px;
      }
      
      .project_des p {
        font-size: 25px;
      }
    }
  
  @media screen and (max-width: 420px) {
    .projects_title h1 {
        font-size: 70px;
      }
      
      .project_des p {
        font-size: 20px;
      }
    }

  @media screen and (max-width:330px){
    .projects_title h1 {
        font-size: 60px;
      }
      
      .project_des p {
        font-size: 20px;
      }
  }