body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  
}

code {
  font-family: 'Poppins', sans-serif;

}

.navbar_padding{
  padding: 1rem 3rem;
}

.section_padding{
  padding: 2rem 4rem;
}


