.card {
  background-color: #5cdb95;
  width: 48%;
  border: 1px solid #43b878;
  border-radius: 10px;
  margin-top: 5rem;
}

.card_color {
  background-color: white;
  padding: 3rem 0.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card_color h2 {
  font-size: 50px;
  /* color: rgb(255, 123, 0); */
  margin-bottom: 0;
  margin-top: 0;
}

.card_color p {
  font-size: 16px;
  color: #0a0e0a;
  margin-top: 0;
}

.card_title {
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 4rem;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 0;
}

.card_title h2 {
  color: #0a0e0a;
  margin-bottom: 0;
}

.card_title p {
  margin-top: 0;
  margin-bottom: 0;
  color: #0a0e0a;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
}

.card_button {
  text-align: left;
  margin: 0 3rem;
}

.card_button button a{
  text-decoration: none;
  color: #0a0e0a;
}


.card_button button {
  margin-top: 0;
  margin-bottom: 4rem;
  background-color: white;
  padding: 1rem 3rem;
  color: #0a0e0a;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 7px #5cdb95;
  cursor: pointer;
}

.card_button button:hover {
  background-color: #0a0e0a;
  color: #5cdb95;
}

/*------------------responsive---------------------*/

@media screen and (max-width: 950px) {
  .card {
    width: 100%;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 450px) {
  .card_color h2 {
    font-size: 40px;
  }

  .card_color p {
    font-size: 15px;
  }
  .card_title {
    padding-top: 2rem;
    padding-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .card_title p {
    font-size: 16px;
    line-height: 25px;
  }

  .card_button {
    margin: 0 2rem;
  }

  .card_button button {
    margin-bottom: 3rem;
    padding: 1rem 1.5rem;
    font-size: 14px;
  }
}
